import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfileBackground = _resolveComponent("ProfileBackground")!
  const _component_OrderDetailDisplay = _resolveComponent("OrderDetailDisplay")!
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createBlock(_component_PageLayout, {
    class: "order-detail-page",
    onOnBack: _ctx.$router.back,
    "back-button": ""
  }, {
    complexBackground: _withCtx(() => [
      _createVNode(_component_ProfileBackground)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_OrderDetailDisplay, {
        order: _ctx.order,
        loading: _ctx.orderLoading,
        class: "pt-8 pb-16"
      }, null, 8, ["order", "loading"])
    ]),
    _: 1
  }, 8, ["onOnBack"]))
}