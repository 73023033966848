
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import OrderDetailDisplay from "@/shared/components/Orders/OrderDetailDisplay.vue";
import { useOrder } from "@/shared/composables/useOrder";
import ProfileBackground from "@/web/views/Profile/ProfileBackground.vue";
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

export default defineComponent({
  props: {},
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const { order, orderLoading } = useOrder({
      orderId: ref(String(route.params.orderId)),
    });

    return {
      t,
      order,
      orderLoading,
    };
  },
  methods: {},
  components: {
    PageLayout,
    OrderDetailDisplay,
    ProfileBackground,
  },
});
